<template>
    <!-- 就餐服务-就餐请假 -->
    <stu-repast-leave-scroll-wrapper ></stu-repast-leave-scroll-wrapper>
</template>
<script>
import StuRepastLeaveScrollWrapper from '@/components/scrollWrapper/StuRepastLeave.vue';

export default {
    name: "SServiceStuRepastLeave",
    components: {
        StuRepastLeaveScrollWrapper,
    },

}
</script>
