<template>
    <!-- 就餐服务-就餐订单 -->
    <repast-order-view-scroll-wrapper ></repast-order-view-scroll-wrapper>
</template>
<script>
import RepastOrderViewScrollWrapper from '@/components/scrollWrapper/RepastOrderView.vue';

export default {
    name: "SServiceStuRepastOrder",
    components: {
        RepastOrderViewScrollWrapper,
    },

}
</script>
